<template>
  <v-container>
    <v-card elevation="0" :loading="!viewReady">
      <v-card-text>
        <v-row>
          <v-col class="d-flex justify-center">
            <!--YA-->
            <v-card
              v-if="formData.labelType.id + formData.printType === '3A4'"
              width="230mm"
            >
              <v-card-title class="headline secondary t-bw-secondary--text">
                {{ formData.labelType.text
                }}<strong>{{ "(" + formData.printType + ")" }}</strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div style="display: none" ref="qrcode"></div>
                <pdf
                  :src="getPdfAction"
                  @num-pages="pageCount = $event"
                  @page-loaded="currentPage = $event"
                  :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="primary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2"
                  >{{ currentPage }} / {{ pageCount }}</span
                  >
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-btn small text color="neuter" @click="save('PRINT')">
                  {{ $t("general.buttons.print") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn small text color="neuter" @click="printCancel">
                  {{ $t("general.buttons.cancel") }}
                </v-btn>
                <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="save('DOWNLOAD')">
                  {{ $t("general.buttons.confirm") }}
                </v-btn>
              </v-card-actions>
            </v-card>
            <!--YA -->
            <v-card
              v-if="formData.labelType.id + formData.printType === '1TRIPLA'"
              width="96mm" max-width="96mm"
            >
              <v-card-title class="headline secondary t-bw-secondary--text">
                {{ formData.labelType.text
                }}<strong>{{ "(" + formData.printType + ")" }}</strong>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-6">
                <div style="display: none" ref="qrcode"></div>
                <pdf
                  :src="getPdfAction"
                  @num-pages="pageCount = $event"
                  @page-loaded="currentPage = $event"
                  :page="currentPage"
                ></pdf>
                <v-row justify="center">
                  <v-btn small class="mr-2" x-large icon @click="backPage">
                    <v-icon color="primary">mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="title pt-2"
                  >{{ currentPage }} / {{ pageCount }}</span
                  >
                  <v-btn small class="ml-2" x-large icon @click="nextPage">
                    <v-icon color="primary">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions class="pa-6">
                <v-btn small text color="neuter" @click="save('PRINT')">
                  {{ $t("general.buttons.print") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn small text color="neuter" @click="printCancel">
                  {{ $t("general.buttons.cancel") }}
                </v-btn>
                <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="save('DOWNLOAD')">
                  {{ $t("general.buttons.confirm") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const { jsPDF } = require("jspdf");
import pdf from "vue-pdf";
import i18n from "@/plugins/i18n";
import LanguajeService from "../../../services/LanguajeService";
import VueBarcode from "vue-barcode";
import moment from "moment";
import * as QRCode from "easyqrcodejs";
import { v4 as uuidv4 } from "uuid";
import {
  convertLatitude,
  convertLongitude,
  isValidLatLong
} from "../../../../utils/convert-to-DMS-format.ts";

export default {
  components: {
    pdf,
    barcode: VueBarcode
  },
  data: () => ({
    loadingSave: false,
    labelData: [],
    profile: null,
    valid: false,
    lineas: null,
    lineas110: null,

    dialog: false,
    editedIndex: -1,
    refers: {
      packingDate: "",
      expirationDate: "",
      manufacturingDate: ""
    },
    formData: {
      labelType: "",
      printType: "",
      productsData: []
    },
    defaultItem: {},
    showDialog: false,
    title: "Información",
    dialogText: "Añada un texto a este dialogo",
    max: 40,
    //Label
    doc: "",
    pdfReady: false,
    viewReady: false,
    dataReady: false,
    routa: "",
    currentPage: 1,
    pageCount: 0,
    trackingLot: "",
    trackingProduct: ""
  }),

  computed: {
    ...mapGetters({
      getLinea: "labels/lineas",
      getLinea110: "labels/lineas110",
      label_data: "general/label_data",
      pre_records_barcode: "general/pre_records_barcode"
    }),
    getPdfAction() {
      return this.dataReady && this.viewReady ? this.getPdf() : "";
    },
    getCodePLU() {
      return index => this.getBarCodePLU(index);
    },
    getQRCodePreCadastro() {
      return this.pre_records_barcode.id;
    },
    decimalFormat() {
      return this.config.company.traceability.structure.number.afterPoint !==
      null
        ? this.config.company.traceability.structure.number.afterPoint
        : 3;
    },
    recordSelected: {
      get() {
        return this.$store.getters["records/record"];
      },
      set(val) {
        this.$store.commit("records/SET_RECORD", val);
      }
    },
    config: {
      get() {
        return this.$store.getters["general/config"];
      },
      set(val) {
        this.$store.commit("general/SET_CONFIG", val);
      }
    }
  },

  created() {
    this.config = JSON.parse(localStorage.getItem("config"));
    this.initialize();
  },
  updated() {
    this.readyView();
  },
  methods: {
    ...mapActions({
      saveLabel: "labels/saveLabel",
      changePrint: "labels/changePrint"
    }),
    translate(key) {
      return LanguajeService.getKey3(key);
    },
    async initialize() {
      this.profile = JSON.parse(localStorage.getItem("profile"));
      this.formData = Object.assign(this.label_data);
      this.setjsPdfdata(this.formData.labelType.id, this.formData.printType);
      console.log(this.formData);
    },
    setjsPdfdata(labelTypeId, printType) {
      let format = null;
      let orientation = null;
      switch (labelTypeId + printType) {

        case "3A4":
          format = [595, 842];
          orientation = "landscape";
          break;
        case "1TRIPLA":
          format = [123.43, 272.13 ];
          orientation = "landscape";
          break;
      }
      this.doc = new jsPDF({
        orientation: orientation,
        unit: "pt",
        format: format
      });
      this.dataReady = true;
    },
    getQRCode128(index) {
      if (this.$route.params.id === "product") {
        return (
          "01" +
          (this.formData.productsData[index].product
            ? this.formData.productsData[index].product.gtin
            : "") +
          (!this.formData.productsData[index].unit ||
          !this.formData.productsData[index].unit.gs1AI
            ? ""
            : this.getGS1AIUnit(
              this.formData.productsData[index].unit.gs1AI,
              this.formData.productsData[index].netWeight
            ) +
            this.getCantGS1(this.formData.productsData[index].netWeight)) +
          ("10" + this.formData.productsData[index].lot).toUpperCase()
        );
      } else {
        return this.getBarCode128(null, index);
      }
    },
    getPdf() {
      const lineas = document.querySelector("img#lineas");

      if (this.formData.labelType.id + this.formData.printType === "3A4") {
        this.formData.save = true;
        _.forEach(this.formData.productsData, (product, index) => {
          let QR = [130, 0];
          let yProduct = [5, 9];
          const printPerPage = 15;
          let totalPrints = product.print;
          let totalPages = Math.ceil(totalPrints / printPerPage);
          let printed = 1;

          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text:
            this.formData.productsData[index].url
          });

          const imgBase64Data =
            qrcodjs._oDrawing._elCanvas.toDataURL("image/png");

          for (let page = 0; page < totalPages; page++) {
            for (let i = 0; i < 5; i++) {
              QR = [130, QR[1]];
              yProduct = [5, yProduct[1]];
              for (let l = 0; l < 3; l++) {
                // Render the img QRCode
                this.doc.addImage(imgBase64Data, "png", QR[0], QR[1], 90, 90);
                // Render Product Data
                let y = yProduct[1];
                this.setFontConfig(11, "#232323", "normal", "bold");
                this.doc.text(
                  i18n.t("reports.fields.product"),
                  yProduct[0],
                  y
                );
                y += 13;
                this.setFontConfig(11, "#232323", "normal", "normal");
                const splitText = this.spliter(this.formData.productsData[index].product, 25);
                _.forEach(splitText, t => {
                  let text =
                    this.doc.splitTextToSize(t, 80) +
                    `${t.length === 25 ? "-" : ""}`;
                  this.doc.text(text, yProduct[0], y);
                  y += 13;
                });
                this.setFontConfig(11, "#232323", "normal", "bold");
                this.doc.text(
                  i18n.t("reports.fields.lot"),
                  yProduct[0],
                  y
                );
                y += 13;
                this.setFontConfig(11, "#232323", "normal", "normal");
                this.doc.text(this.formData.productsData[index].lot, yProduct[0], y);

                y += 22;
                this.setFontConfig(16, "#232323", "normal", "bold");
                this.doc.text((printed + product.printed) + "/" + product.printLimit, yProduct[0], y);

                const x = 280;
                QR = [QR[0] + x, QR[1]]
                yProduct = [yProduct[0] + x, yProduct[1]];

                printed++;

                if (printed > totalPrints) {
                  break;
                }
              }

              if (printed > totalPrints) {
                break;
              }

              const y = 125;
              QR = [QR[0], QR[1] + y];
              yProduct = [yProduct[0], yProduct[1] + y];
            }

            if (printed <= totalPrints) {
              QR = [130, 0]
              yProduct = [5, 9]

              this.doc.addPage();
            }
          }
        });
      }
      if (this.formData.labelType.id + this.formData.printType === "1TRIPLA") {
        this.formData.save = true;
        _.forEach(this.formData.productsData, (product, index) => {
          let QR = [22, 64];
          const printPerPage = 3;
          let totalPrints = product.print;
          let totalPages = Math.ceil(totalPrints / printPerPage);
          let printed = 1;

          let qrcodjs = new QRCode(this.$refs.qrcode, {
            text:
            this.formData.productsData[index].url
          });

          const imgBase64Data = qrcodjs._oDrawing._elCanvas.toDataURL("image/png");
          let splitLot = (i18n.t("reports.fields.lot") + ":" + this.formData.productsData[index].lot).substring(0, 25);
          let splitPowered = ("Powered by AgTrace").substring(0, 25);

          for (let page = 0; page < totalPages; page++) {
            for (let i = 0; i < 1; i++) {
              for (let l = 0; l < 3; l++) {
                // Render Lot
                this.setFontConfig(4, "#232323", "normal", "bold");

                // Coordenadas do QR code
                const qrX = QR[0];
                const qrY = QR[1];

                // Renderiza o texto do lote
                this.doc.text(splitLot, qrX + 21, qrY - 2, "center");

                // Renderizar a imagem QRCode
                this.doc.addImage(imgBase64Data, "png", qrX, qrY, 42.52, 42.52);

                // Renderiza o texto "Powered"
                this.setFontConfig(3, "#232323", "normal", "bold");
                this.doc.text(splitPowered, qrX + 21, qrY + 47, "center");

                // Renderiza o texto "Printed" com rotação de 90°
                this.setFontConfig(4, "#232323", "normal", "bold");

                const printedText = (printed + product.printed) + "/" + product.printLimit;
                const textSize = this.doc.getTextWidth(printedText);

                const qrCenterY = qrY + 42.52 / 2;
                const textOffsetY = textSize / 2;
                let printedTextX = qrX - 2;
                let printedTextY = qrCenterY + textOffsetY;

                this.doc.text(printedText, printedTextX, printedTextY, { angle: 90 });

                // Atualizar coordenadas para o próximo QR
                const x = 94;
                QR = [QR[0] + x, QR[1]];
                printed++;

                if (printed > totalPrints) {
                  break;
                }
              }

              if (printed > totalPrints) {
                break;
              }
              const y = 86.4;
              QR = [QR[0], QR[1] + y];
            }
            if (printed <= totalPrints) {
              QR = [22, 64]
              this.doc.addPage();
            }
          }
        });
      }
      // PDF ready
      this.pdfReady = true;
      // Return PDF data string
      return this.doc.output("datauristring");
    },
    getLabelData(x, y, fontSizeG, fontSizeP, index) {
      this.writeBoldAndNormalText(
        "**" +
        i18n.t("records.label.titles.distributedBy") +
        "**" +
        " : " +
        this.formData.productsData[index].form.distributedBy +
        " " +
        this.formData.productsData[index].form.documentValueDistributed,
        x,
        y,
        fontSizeG,
        fontSizeG
      );
      this.setFontConfig(fontSizeP, "#232323", "normal", "normal");
      y += 9;
      this.doc.text(
        this.formData.productsData[index].form.allAddressDistributed,
        x,
        y
      );
      y += 10;
      this.writeBoldAndNormalText(
        "**" +
        i18n.t("records.label.titles.producedBy") +
        "**" +
        " : " +
        this.formData.productsData[index].form.producedBy +
        " " +
        this.formData.productsData[index].form.documentValueProduced,
        x,
        y,
        fontSizeG,
        fontSizeG
      );
      this.setFontConfig(fontSizeP, "#232323", "normal", "normal");
      y += 9;
      this.doc.text(
        this.formData.productsData[index].form.allAddressProduced,
        x,
        y
      );
      let datas = "";
      if (
        (this.formData.productsData[index].packingDate !== "" &&
          this.formData.productsData[index].packingDate !== null) ||
        (this.formData.productsData[index].expirationDate !== "" &&
          this.formData.productsData[index].expirationDate !== null)
      ) {
        y += 10;
        if (
          this.formData.productsData[index].expirationDate !== "" &&
          this.formData.productsData[index].expirationDate !== null
        ) {
          datas =
            "**" +
            (this.formData.labelType.id + this.formData.printType ===
            "4DUPLO" ||
            this.formData.labelType.id + this.formData.printType === "6SIMPLE"
              ? i18n.t("records.label.titles.harvestDate")
              : i18n.t("records.label.titles.expirationDate")) +
            "**" +
            "  : " +
            this.formData.productsData[index].expirationDate;
        }
        if (
          this.formData.productsData[index].packingDate !== "" &&
          this.formData.productsData[index].packingDate !== null
        ) {
          datas =
            datas +
            "   " +
            "**" +
            i18n.t("records.label.titles.packingDate") +
            "**" +
            ": " +
            this.formData.productsData[index].packingDate;
        }
        this.writeBoldAndNormalText(datas, x, y, fontSizeG, fontSizeG);
      }
      y += 10;
      this.writeBoldAndNormalText(
        "**" +
        this.getLoteSafraName(index).name +
        "**" +
        ": " +
        this.getLoteSafraName(index).record_value,
        x,
        y,
        fontSizeG,
        fontSizeG
      );
      if (
        this.formData.labelType.id + this.formData.printType !==
        "6SIMPLE"
      ) {
        if (
          this.formData.productsData[index].additionalInformation !== "" &&
          this.formData.productsData[index].additionalInformation !== null
        ) {
          y += 8;
          this.writeBoldAndNormalText(
            this.formData.productsData[index].additionalInformation,
            x,
            y,
            fontSizeP,
            fontSizeP
          );
        }
      }
    },

    toStartCase(text) {
      const words = text.split(" ");

      return words
        .map(word => {
          if (word)
            return word[0].toUpperCase() + word.substring(1).toLowerCase();
        })
        .join(" ");
    },

    getTopLabelDataGS1v2(
      x,
      y,
      fontSizeG,
      fontSizeP,
      index,
      splitSizeAddress,
      splitSizeLoc,
      addressColSpan
    ) {
      const loc1 = this.toStartCase(
        this.isNotExist(
          this.formData.productsData[index].form.primary_origin_name
        )
      );
      const loc2 = this.toStartCase(
        this.isNotExist(
          this.formData.productsData[index].form.sdi_origin_name
        )
      );
      this.writeBoldAndNormalText(
        "**" + i18n.t("records.fields.origin") + "**" + " : ",
        x,
        y,
        fontSizeG,
        fontSizeG
      );
      y += fontSizeG + 2;
      this.doc.text(loc1, x + 10, y);
      y += fontSizeG + 2;
      this.doc.text(loc2, x + 10, y);
      y += fontSizeG + 2;

      const text = this.doc.splitTextToSize(
        this.isNotExist(
          this.formData.productsData[index].form.allAddressProduced
        ),
        splitSizeAddress
      );
      this.writeBoldAndNormalText(
        "**" +
        i18n.t("records.label.titles.allAddressProduced") +
        "**" +
        " :    ",
        x,
        y,
        fontSizeG,
        fontSizeG
      );
      this.doc.text(text, x + addressColSpan, y);
      if (text && text.length > 1) {
        y += fontSizeG + 2;
      }
      if (text && text.length > 2) {
        y += fontSizeG + 2;
      }
      y += fontSizeG + 2;

      if (
        isValidLatLong(
          this.formData.productsData[index].form.sdi_origin_lat, true
        ) &&
        isValidLatLong(
          this.formData.productsData[index].form.sdi_destination_lat, true
        )
      ) {
        this.writeBoldAndNormalText(
          "**" +
          i18n.t("records.label.titles.lat") +
          "**" +
          " : " +
          convertLatitude(
            this.formData.productsData[index].form.sdi_origin_lat
          ) +
          "** - **" +
          "**" +
          i18n.t("records.label.titles.long") +
          "**" +
          " : " +
          convertLongitude(
            this.formData.productsData[index].form.sdi_origin_long
          ),
          x,
          y,
          fontSizeG,
          fontSizeG
        );
      }
    },

    getLabelDataGS1v2(x, y, fontSizeG, fontSizeP, index, productObject) {
      let product = this.getObjectProduct(index);
      if (product) {
        this.writeBoldAndNormalText(
          i18n.t("records.label.titles.product") +
          " : **" +
          this.translate(product.language_key, true) +
          "**",
          x,
          y,
          fontSizeG,
          fontSizeG
        );
      }
      y += fontSizeG + 2;
      this.writeBoldAndNormalText(
        this.getLoteSafraName(index).name +
        ": **" +
        this.getLoteSafraName(index).record_value +
        "**",
        x,
        y,
        fontSizeG,
        fontSizeG
      );
      y += fontSizeG + 2;
      if (
        productObject.netWeight !== "" &&
        productObject.netWeight !== null
      ) {
        this.writeBoldAndNormalText(
          i18n.t("records.label.titles.netWeight") +
          ": " +
          "**" +
          parseFloat(productObject.netWeight).toFixed(this.decimalFormat) +
          " " +
          productObject.unit.code +
          "**",
          x,
          y,
          fontSizeG,
          fontSizeG
        );
      }
      let datas = "";
      if (
        (this.formData.productsData[index].packingDate !== "" &&
          this.formData.productsData[index].packingDate !== null) ||
        (this.formData.productsData[index].expirationDate !== "" &&
          this.formData.productsData[index].expirationDate !== null)
      ) {
        y += fontSizeG + 2;
        if (
          this.formData.productsData[index].expirationDate !== "" &&
          this.formData.productsData[index].expirationDate !== null
        ) {
          datas =
            i18n.t("records.label.titles.expirationDate") +
            "  : **" +
            this.formatDate(
              this.formData.productsData[index].expirationDate,
              "DD/MM/YYYY"
            ) +
            "**";
        }
        if (
          this.formData.productsData[index].packingDate !== "" &&
          this.formData.productsData[index].packingDate !== null
        ) {
          datas =
            datas +
            "   " +
            i18n.t("records.label.titles.packingDate") +
            ": **" +
            this.formatDate(
              this.formData.productsData[index].packingDate,
              "DD/MM/YYYY"
            ) +
            "**";
        }
        this.writeBoldAndNormalText(datas, x, y, fontSizeG, fontSizeG);
      }
      if (
        this.formData.productsData[index].additionalInformation !== "" &&
        this.formData.productsData[index].additionalInformation !== null
      ) {
        y += fontSizeG + 2;
        this.writeBoldAndNormalText(
          this.formData.productsData[index].additionalInformation,
          x,
          y,
          fontSizeP,
          fontSizeP
        );
      }
    },

    isNotExist(field) {
      if (field === null) return "-";

      return field;
    },
    formatDate(date, format) {
      return moment(date, "YYYY-MM-DD").format(format);
    },

    writeBoldAndNormalText(
      inputValue,
      startX,
      starty,
      fontSizeBold,
      fontSize
    ) {
      // https://codepen.io/AndreKelling/pen/BaoLWao
      // setup config
      const arrayOfNormalAndBoldText = inputValue.split("**");
      arrayOfNormalAndBoldText.map((text, i) => {
        this.setFontConfig(fontSizeBold, "#232323", "normal", "bold");
        // every even item is a normal font weight item
        if (i % 2 === 0) {
          this.setFontConfig(fontSizeBold, "#232323", "normal", "normal");
        }
        this.doc.text(text, startX, starty);
        startX = startX + this.doc.getStringUnitWidth(text) * fontSize;
      });
    },
    readyView() {
      this.viewReady = true;
    },
    download() {
      this.doc.save("etiqueta.pdf");
      this.printCancel();
    },
    print() {
      this.doc.autoPrint();
      window.open(this.doc.output("bloburl"), "_blank");
      this.printCancel();
    },
    getBarCode128(exclude, index) {
      if (!exclude) {
        exclude = [];
      }
      const product = this.getObjectProduct(index);
      return (
        (exclude.includes("01") || !product ? "" : "01" + product.gtin) +
        (exclude.includes("u") ||
        !this.formData.productsData[index].unit ||
        !this.formData.productsData[index].unit.gs1AI
          ? ""
          : this.getGS1AIUnit(
            this.formData.productsData[index].unit.gs1AI,
            this.formData.productsData[index].netWeight
          ) +
          this.getCantGS1(this.formData.productsData[index].netWeight)) +
        (exclude.includes("10") || !this.getLoteSafra(index)
          ? ""
          : "10" + this.getLoteSafra(index))
      );
    },
    getBarCode128Text(exclude, index) {
      if (!exclude) {
        exclude = [];
      }
      const product = this.getObjectProduct(index);
      return (
        (exclude.includes("01") || !product ? "" : "(01)" + product.gtin) +
        (exclude.includes("u") ||
        !this.formData.productsData[index].unit ||
        !this.formData.unit.productsData[index].gs1AI
          ? ""
          : "(" +
          this.getGS1AIUnit(
            this.formData.productsData[index].unit.gs1AI,
            this.formData.productsData[index].netWeight
          ) +
          ")" +
          this.getCantGS1(this.formData.productsData[index].netWeight)) +
        (exclude.includes("10") || !this.getLoteSafra(index)
          ? ""
          : "(10)" + this.getLoteSafra(index))
      );
    },
    getGS1AIUnit(gs1AI, cant) {
      let cantTotal = cant.split(".");
      if (cantTotal.length > 1) {
        return gs1AI.toString() + cant.split(".")[1].length;
      } else {
        return gs1AI.toString() + 0;
      }
    },
    getCantGS1(cant) {
      let cantTotal = cant.split(".");
      let result = null;
      if (cantTotal.length > 1) {
        result = cantTotal[0] + cantTotal[1];
      } else {
        result = cant;
      }
      return result.toString().padStart(6, "000000");
    },
    getBarCodePLU(index) {
      const product = this.getObjectProduct(index);
      return product ? product.gtin : "";
    },
    getProduct(index) {
      return this.formData.productsData[index].product_value;
    },
    printCancel() {
      this.$router.push({ name: "traceability-view" })
    },
    getLoteSafra(index) {
      let lote = null;
      if (this.formData.productsData[index].seasson) {
        lote = this.formData.productsData[index].seasson.id;
      } else {
        lote = this.formData.productsData[index].lot;
      }
      return lote;
    },
    getLoteSafraName(index) {
      let loteResult = { name: "", data: "", record_value: "" };
      loteResult.name = this.translate(
        this.formData.productsData[index].structureLoteSafra.structure
          .language_key
      );
      loteResult.record_value =
        this.formData.productsData[
          index
          ].structureLoteSafra.recordStructureValue.record_value[0];
      return loteResult;
    },
    getObjectProduct(index) {
      return this.formData.productsData[index].product;
    },
    // Utils
    setFontConfig(size, color, style, type) {
      if (size) {
        this.doc.setFontSize(size);
      }
      if (color) {
        this.doc.setTextColor(color);
      }
      if (style) {
        this.doc.setFont(undefined, style, type);
      }
    },
    nextPage() {
      if (this.pageCount >= this.currentPage + 1) this.currentPage++;
    },
    backPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    spliter(str, nr) {
      const parts = [];
      for (let i = 0, length = str.length; i < length; i += nr) {
        parts.push(str.substring(i, i + nr));
      }
      return parts;
    },
    actionConfirm(action) {
      if (action === "PRINT") {
        this.print();
      } else if ("DOWNLOAD") {
        this.download();
      }
    },
    async save(action) {
      this.loadingSave = true;
      if (this.formData.save) {
        this.labelData = {
          id: this.formData.productsData[0].id,
          printed: this.formData.productsData[0].print
        };
        await this.changePrint([this.labelData, this.$toast]).then(reponse => {
          if (reponse.status === 200) {
            this.actionConfirm(action);
          }
        });
      } else {
        this.actionConfirm(action);
      }
      this.loadingSave = false;
    }
  }
};
</script>

<style scoped></style>